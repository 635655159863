module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-CSNM6K7N97"],"pluginConfig":{"head":true,"enableWebVitalsTracking":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Verinite","short_name":"Verinite","start_url":"/","icon":"src/img/icon/icon-192x192.png","icons":[{"src":"src/img/icon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/img/icon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/img/icon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/img/icon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/img/icon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/img/icon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"src/img/icon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"src/img/icon/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"879a2d3f0002a2cd27ab7903ab512ca0"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
